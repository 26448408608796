.blog-nav {
  font-size: 16px;
  letter-spacing: -0.4px;
  padding: 15px 0;
  &--opened {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #F2F2F2;
    min-height: 50vh;
  }
  &__link {
    margin-bottom: 0;
  }
  .blog-search {
    input {
      box-shadow: none;
      border-radius: 0;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom: 1px solid $grey2;
      padding: 14px;
      background-color: transparent;
    }
  }
}

.blog {
  &__controls {
    .btn {
      &:hover {
        svg path {
          fill: white;
        }
      }
    }
  }
}

.blog-search {
  input {
    box-shadow: 0 3px 6px #00000026;
    border-radius: 6px;
    border: none;
    padding: 15.5px 10px 15.5px;
    font-size: 16px;
    letter-spacing: -0.4px;
    &::placeholder {
      color: #222222;
    }
  }
  &-bg {
    background: #1D1D1F 0 0 no-repeat padding-box;
    opacity: 0.4;
    z-index: 1;
    top: 159.15px;
    bottom: 0;
  }
}

.blog-carrousel-image {
  position: relative;
  aspect-ratio: 816 / 505;
}

.blog-item {
  &__container {
    background-color: white;
    border-radius: 15px;
    margin-right: 40px;
    overflow: hidden;
    padding-left: 0px !important;
  }
  &__content {
    padding: 3.6em 2em 5.5em 2em;
  }
}

/*! purgecss start ignore */
.blog-results {
  &__item {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    &--lg {
      min-height: 362px;
    }
  }
  &__img-container {
    overflow: hidden;
    &--md {
      max-height: 265px;
    }
    &--sm {
      max-height: 170px;
    }
    &--rounded {
      border-radius: 15px;
      width: 300px;
      height: 168px;
    }
  }
}
/*! purgecss end ignore */

@include media-breakpoint-up(xxxl) {
  .blog-results {
    &__item {
      h4 {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
      }
    }
  }
  .blog-item {
    &__container {
      min-width: 816px;
      width: 816px;
    }
    &__content {
      h4 {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
      }
    }
  }
}

@include media-breakpoint-between(xxl, xxxl) {
  .blog-item {
    &__container {
      min-width: 716px;
      width: 716px;
    }
  }
}

@include media-breakpoint-between(xxl, xl) {
  .blog-nav {
    font-size: 14px;
    letter-spacing: -0.35px;
  }
}

@include media-breakpoint-between(md, xxl) {
  .blog-item {
    &__container {
      min-width: 616px;
      width: 616px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog {
    &__controls {
      width: 816px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .blog-nav {
    padding: 8px 0 11px;
    &__link {
      margin-bottom: 13px;
    }
  }
  .blog-item {
    &__content {
      padding: 1em 1em 0.85em 1em;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog-carrousel-image {
    width: calc(100vw - 160px);
  }
  .blog-container {
    padding-left: 25%;
  }
}

@include media-breakpoint-down(md) {
  .blog-nav {
    &--opened {
      min-height: 100vh;
    }
  }
  .blog {
    &__controls {
      .btn-icon {
        width: 40px;
        height: 40px;
      }
    }
  }
  .blog-item {
    &__container {
      min-width: 100%;
      width: 100%;
    }
    &__content {
      .lead {
        font-size: 12px;
        letter-spacing: -0.33px;
      }
    }
  }
  /*! purgecss start ignore */
  .blog-results {
    &__item {
      &--lg {
        min-height: initial;
      }
    }
    &__img-container {
      &--md {
        max-height: initial;
      }
      &--sm {
        max-height: initial;
      }
      &--rounded {
        width: 100%;
        height: auto;
      }
    }
  }
  /*! purgecss end ignore */
}

@include media-breakpoint-up(sm) {
  .blog-carrousel-image {
    display: block;
    width: 100%;
    max-height: 490px;
    overflow: hidden;
    background-color: white;
  }
}

@include media-breakpoint-down(sm) {
  .blog-results {
    &__img-container {
      max-height: 185px;
    }
  }
  .blog-carrousel-image {
    display: block;
    width: 100%;
    max-height: 50vw;
    overflow: hidden;
    background-color: white;
  }
}
